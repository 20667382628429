@font-face {
	font-family: 'ReplicaStd-Regular';
	src: 	url('../fonts/ReplicaStd-Regular.eot');
	src: 	url('../fonts/ReplicaStd-Regular.eot?#iefix') format('embedded-opentype'),
				url('../fonts/ReplicaStd-Regular.woff') format('woff'),
				url('../fonts/ReplicaStd-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'ReplicaStd-Bold';
	src: 	url('../fonts/ReplicaStd-Bold.eot');
	src: 	url('../fonts/ReplicaStd-Bold.eot?#iefix') format('embedded-opentype'),
				url('../fonts/ReplicaStd-Bold.woff') format('woff'),
				url('../fonts/ReplicaStd-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'value-serif-bold';
    src: url('../fonts/value-serif-bold.eot');
    src: url('../fonts/value-serif-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/value-serif-bold.woff') format('woff'),
         url('../fonts/value-serif-bold.woff2') format('woff2'),
         url('../fonts/value-serif-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}