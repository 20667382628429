.scroll-content {
	padding-top: $header-height-l;
}

.l-intro {
	.scroll-content {
		padding-top: 0;
	}
}

.l-scroll--case {
	z-index: 1;
	// pointer-events: ;

	.scroll-content {
		// pointer-events: all;
		// background-color: #efefef;
	}
}

@media all and (max-width: $bp-s) {
	.scroll-content {
		padding-top: $header-height-s;
	}
}