.c-slide {
	// float: left;
	position: relative;
	padding-bottom: $slideshow-height-l;
	text-align: left;

	&:after {
		content: '';
		display: block;
		height: 0;
		width: 100%;
	}

	&:selected {
		border: none;
	}
}

.c-slide__img {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}