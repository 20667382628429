.c-logo {
	position: absolute;
	left: $col-padding-l;
	display: block;
	margin-top: 30px;
	width: 194px;
	height: 29px;
	overflow: hidden;
}

.menu-is-sticky {
	.c-logo {
		left: $window-padding-l;
		margin-left: $col-padding-l;
	}
}

@media all and (max-width: $bp-s) {
	.c-logo {
		left: 0;
		margin-top: 23px;
		width: 200px * 0.8;
		height: 29px * 0.8;

		svg {
			height: 100%;
			width: auto;
		}
	}

	.menu-is-sticky {
		.c-logo {
			margin-left: 0;
			left: $window-padding-s;
		}
	}
}