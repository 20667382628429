.c-video {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}

.c-video__inner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}