.c-close-icon {
	display: block;
	position: relative;
	width: 33px;
	height: 33px;

	margin: -5px -4px;

	&:before, &:after {
		content: '';
		position: absolute;
		top: 15px;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: currentColor;
	}

	&:before {
		transform: rotate(-45deg);
	}

	&:after {
		transform: rotate(45deg);
	}
}