/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

.h-cover {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	img.lazyload {
		width: 100%;
		height: 100%;
	}

	img, video {
		position: absolute;
    left: -9999px;
    right: -9999px;
    top: -9999px;
    bottom: -9999px;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
	}
}

.h-cover--no-overflow-x {
	overflow: visible;
}

.h-cover--left {
	img {
		left: auto;
		right: 0;
		// transform: translateX(50%);
	}
}

.h-cover--right {
	img {
		left: 0;
		right: auto;
		// transform: translateX(-50%);
	}
}

.h-fit-cover-left {
	width: 100%;
	height: 100%;

	 img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 100% 50%;
		font-family: 'object-fit: cover; object-position: 100% 50%;';
	}
} 

.h-fit-cover-right {
	width: 100%;
	height: 100%;

	 img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position:  0 50%;
		font-family: 'object-fit: cover; object-position: 0 50%;';
	}
} 

.h-blend {
	mix-blend-mode: multiply;
}

.ie-blend {
	img, video {
		opacity: 0.3;
	}
}

.h-vertically-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h-no-scroll {
	overflow-y: hidden;
}

.h-full-vh {
	height: 100vh;
}

.h-is-relative {
	position: relative;
}

.h-link-u {
	a:link {
		display: inline;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 1px;
			height: 1px;
			background-color: currentColor;
		}
	}

	a:hover:after {
		background-color: transparent;
	}
}

.h-link-u--l {
	a:link:after {
		bottom: 4px;
	}
}

@media all and (max-width: $bp-m) {
	.h-link-u--l {
		a:link:after {
			bottom: 3px;
		}
	}
}

@media all and (max-width: $bp-s) {
	.h-link-u--l {
		a:link:after {
			bottom: 1px;
		}
	}
}

.h-ignore-wrapper-margin {
	min-width: 100%;
	margin: 0 (-10% * 1.25);

	> * {
		margin: 0 ($col-padding-l * -1 - 1);
		min-width: 100%;
	}
}

.ie {
	.h-blend--no-hover {
		img, video {
			opacity: $ie-bg-opacity;
		}
	}
}

.ie {
	.hover-background:hover {
		img, video {
			opacity: $ie-bg-opacity;
		}
	}
}

.h-no-case-margin {
	margin-top: $case-margin-l * -1;
	margin-bottom: 0 !important;
}

.h-case-margin-top {
	margin-top: $case-margin-l;
}

.is-disabled {
	visibility: none;
	z-index: 0;
	// display: none;
}

.is-hidden {
	opacity: 0;
	pointer-events: none;
}

.is-hovering {
	cursor: pointer;
}

.h-blink:hover {
	@extend .blink;
}

@media all and (max-width: $bp-s) {
	.h-ignore-wrapper-margin {
		min-width: 100%;
		margin: 0 ($window-padding-s * -1);

		> * {
			margin: 0;
			min-width: 100%;
		}
	}

	.h-case-margin-top {
		margin-top: $case-margin-s;
	}
}