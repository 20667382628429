.c-rotate {
	display: none;
}

@media all and (max-width: 760px) and (orientation: landscape) {
	.c-rotate {
		display: block;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: rgba(0,0,0,0.8);
		z-index: 20;

		p {
			padding: 30px;
			font-size: 24px;
			color: $white;
		}
	}
}