.c-slideshow-btn {
	position: absolute;
	top: 0;
	width: 50%;
	height: 100%;
	z-index: 100;

	cursor: pointer;
}

.c-slideshow-btn--visible {
	top: 100%;
	width: auto;
	height: auto;

	&.c-slideshow-btn--prev {
		transform: rotate(180deg);
		right: 30px;
		margin-top: 13px;
	}

	&.c-slideshow-btn--next {
		right: 0;
		margin-top: 17px;
	}
}

.c-slideshow-btn--next:not(.c-slideshow-btn--visible) {
	right: 0;
	cursor: url('../img/cursor_next.svg') 10 8,
					url('../img/cursor_next.png') 10 8,
					e-resize;
}

.c-slideshow-btn--prev:not(.c-slideshow-btn--visible) {
	left: 0;
	cursor: url('../img/cursor_prev.svg') 10 8,
					url('../img/cursor_prev.png') 10 8,
					w-resize;
}