.c-project-card {
	position: relative;
	float: left;
	min-width: 33.33%;
	min-height: 50%;
	padding: 3px;
	margin: -3px 0 9px;

	font-family: $repl-stack;

	&:hover, &.clicked {
		.c-project-card__hover {
			opacity: 1;
		}
	}
}

.c-project-card--large {
	min-width: 66.66%;
}

.c-project-card__inner {
	position: absolute;
	left: 3px;
	top: 3px;
	bottom: 3px;
	right: 3px;

	height: 100%;
}

.c-project-card__img {
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: -3px;
		z-index: 1;
	}
}

.c-project-card__caption {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0 15% 30px 25px;
}

.c-project-card__title {
	margin-bottom: 7px;
	font-family: $serif-stack;
	font-size: 22px;
}

.c-project-card__subtitle {
	font-size: $xs-text;
	line-height: 15px;
	margin-bottom: 3px;
}

.c-project-card__hover {
	position: absolute;
	top: 29px;
	left: 24px;
	right: 24px;

	font-family: $serif-stack;
	opacity: 0;
	font-size: 28px;
	line-height: 38px;
}

.c-project-card__category {
	display: none;
}

.c-project-card__footnote {
	text-transform: lowercase;
	font-size: $xs-text;
	letter-spacing: 0.3px;
	margin-top: 20px;
}

@media all and (max-width: $bp-s) {
	.c-project-card, {
		width: 50%;
		min-height: 33.33%;
		padding: 0.5px;
		margin: -0.5px 0 1.5px;
	}

	.c-project-card--large {
		min-width: 50%;
	}

	.c-project-card__inner {
		left: 0.5px;
		top: 0.5px;
		bottom: 0.5px;
		right: 0.5px;
	}

	.c-project-card__title, .c-project-card__subtitle, .c-project-card__hover {
		display: none;
	}

	.c-project-card__caption {
		top: 0;
	}

	.c-project-card__category {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		padding: 20px;
		transform: translateY(-50%);

		font-family: $serif-stack;
		font-size: 15px;

		text-align: center;
	}

	.c-project-card__footnote {
		display: none;
	}
}