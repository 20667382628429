.c-header-contact {
	position: absolute;
	right: 46px;
	top: 29px;

	text-align: right;
}

.c-header-contact__item {
	font-family: $repl-stack;
	font-size: 10.5px;
	line-height: 13px;
	letter-spacing: 0.7px;
}

.menu-is-sticky {
	.c-header-contact {
		margin-right: 46px;
		right: $window-padding-l;
	}
}

@media all and (max-width: $bp-s) {
	.c-header-contact {
		opacity: 0;
	}
}