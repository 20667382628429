.c-cta {
	display: inline-block;
	position: relative;
	margin-top: 56px;

	font-size: 16px;

	&:after {
		content: '';
		position: absolute;
		left: $col-padding-l;
		bottom: 2px;
		opacity: 0.3;
	}
}

.c-list + .c-cta {
	margin-top: 21px;
}

@media all and (max-width: $bp-s) {
	.c-cta {
		display: none;
	}
}