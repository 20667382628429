.c-module {
	position: relative;
	height: 100%;
	width: 100%;
  	overflow: hidden;

	font-family: $repl-stack;

	// &:hover, &.c-module--is-hovering {
	// 	.c-module__hover {
	// 		opacity: 1;
	// 	}
	// }
}

.ie .c-module.c-module--is-hovering {
	img, video {
		opacity: $ie-bg-opacity;
	}
}

.c-module--intro {
	overflow: visible;

	img {
		display: none;
	}

	.c-module__hover {
		opacity: 1;
		top: 133px;
	}
}

.c-module--large {

}

.c-module__active-block {
	position: absolute;
	left:0;
	bottom: 0;
	right: 0;
	height: 30%;
}

.c-module__link {
	display: block;
}

.c-module__caption {
	position: absolute;
	bottom: 67px;
	left: 48px;
	// right: -80%;
}

.c-module__caption, .c-module__hover {
	padding-right: 48px;
}

.c-module__title {
	margin-bottom: 6px;

	font-size: 27px;
	font-family: $serif-stack;
	line-height: 30px;
	letter-spacing: 0.3px;
}

.c-module__subtitle {
	margin-bottom: 27px;

	font-size: 18px;
}

.c-module__footnote {
	text-transform: lowercase;
	font-size: $xs-text;
}

.c-module__hover {
	position: absolute;
	top: 116px;
	left: 51px;

	font-family: $serif-stack;
	font-size: 44px;
	line-height: 54px;
	letter-spacing: 0.1px;
	opacity: 1;
	
	&.is-hidden {
		opacity: 0;
	}
}

.clicked {
	.c-module__hover.is-hidden {
		opacity: 1;
	}
}


// For touch devices
// .is-touch {
// 	.c-module {
// 		&:hover, &.c-module--is-hovering {
// 			.c-module__hover {
// 				opacity: 0;
// 			}
// 		}
// 	}

// 	.c-module--intro {
// 		.c-module__footnote {
// 			opacity: 1;
// 		}
// 	}
// }

@media all and (min-width: 1920px) {
	.c-module__hover {
		font-size: 50px;
		line-height: 60px;
	}
}


@media all and (max-width: $bp-m), all and (max-height: 740px) {
	.c-module__hover, .c-module__caption {
		padding-right: 24px;
	}

	.c-module__hover, .c-module--intro .c-module__hover {
		top: 42px;
		left: 22px;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.5px;
	}

	.c-module__title {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.5px;
	}

	.c-module__subtitle {
		font-size: 14px;
	}

	.c-module__caption {
		bottom: 34px;
		left: 22px;
	}

	.c-module--large {
		.c-module__hover, .c-module__caption {
			padding-right: 0;
		}
	}
}

@media all and (orientation: portrait) {
	.c-module--large {
		overflow: visible;
	}

	.c-module--intro {
		z-index: 3;

		.c-module__caption {
			bottom: -100%;
			left: 0;
			right: 0;
			padding: 0;
			text-align: center;
			margin-bottom: 36px;
		}
	}

	.c-module {
		.c-module__active-block {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom:0;
			height: auto;
		}
	}

	.c-module--large {
		.c-module__hover {
			right: -100%;
		}

		.c-module__caption {
			right: 0;
		}

		.c-module__active-block {
			position: absolute;
			left: 0;
			right: -100%;
			top: 0;
			bottom:0;
			height: auto;
		}
	}

	.clicked {
		.c-module__footnote {
			animation-duration: 200ms;
			animation-name: blink;
			animation-iteration-count: infinite;
			animation-direction: alternate;
		}
	}
}


@media all and (max-width: $bp-xs) {
	.c-module__hover {
		font-size: 21px;
		line-height: 26px;
	}
}