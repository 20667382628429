.l-fullpage {
	position: fixed;
	left: 100% / 20 * 2;
	right: 100% / 20 * 2;
	top: $header-height-l;
	bottom: $window-bottom-padding-l;
	margin: 0 $col-padding-l;
	overflow: hidden;

	transition: transform 0.8s ease;
}

.menu-is-open {
	.l-fullpage {
		transform: translate3d($menu-width-l * -1, 0, 0);
	}
}

.l-fullpage__inner {
	width: 100%;
	height: 100%;
	overflow-y: hidden;

	opacity: 0;
	transition: opacity 700ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.l-fullpage__inner--childmargins {
	margin: 0 -3px;
	width: auto;
	min-width: 100%;
}

.fullpage-is-loaded {
	.l-fullpage__inner {
		opacity: 1;
	}
}

@media all and (max-width: $bp-s) {
	.l-fullpage {
		top: $header-height-s;
		left: $window-padding-s;
		right: $window-padding-s;
		bottom: $window-bottom-padding-s;
		margin: 0;
	}

	.menu-is-open {
		.l-fullpage {
			transform: translate3d($menu-width-s * -1, 0, 0);
		}
	}
}