.c-menu-item {
	margin-bottom: -2px;
	font-family: $serif-stack;
	font-size: 35px;
	letter-spacing: -0.5px;

	&.is-active, &:hover {
		a:after {
			width: 100%;
		}
	}

	a:after {
		bottom: 5px;
	}
}

@media all and (max-width: $bp-s) {
	.c-menu-item {
		font-size: 28px;
		letter-spacing: -0.2px;
		line-height: 40px;
	}
}