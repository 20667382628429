.c-fullpage-arrow {
	position: fixed;
	width: 60px;
	height: 40px;
	left: 50%;
	margin-left: -30px;
	padding: 11px 0 0 19px;
	opacity: 1;

	transition: opacity 0.2s ease;

	&.is-hidden {
		opacity: 0;
	}
}

.c-fullpage-arrow--prev {
	top: 30px;
	z-index: 10;

	img {
		transform: rotate(-180deg);
	}
}

.c-fullpage-arrow--next {
	bottom: 7px;
	z-index: 2;
}

@media all and (max-width: $bp-s) {
	.c-fullpage-arrow--prev {
		top: 25px;
	}

	.c-fullpage-arrow--next {
		bottom: -4px;
	}
}