// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html, body {
	width: 100%;
	height: 100%;
}

body {
	overflow-x: hidden;
}

#scroll {
	height: 100vh;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 


/*
	Normalize lists
*/

ul, ol {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}


figure, h1, h2, h3, h4, h5 ,p {
	margin: 0;
}

h1, h2, h3, h4, h5 {
	font-weight: normal;
}

a:link, a:visited, a:active, a:hover {
	color: inherit;
	text-decoration: none;
	color: inherit;
}