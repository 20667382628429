.c-touch-intro {
	position: absolute;
	left: 38px;
	right: 24px;
	top: 76px;
	font-family: $repl-stack;
	font-size: 28px;
	line-height: 32px;

	max-width: 400px;
	display: none;
}

.is-touch {
	.c-touch-intro {
		display: block;
	}
}