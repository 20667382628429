.c-index {
	margin-bottom: 60px;

	text-align: justify;
  text-align-last: justify;
  font-family: $repl-stack;
  font-size: $xs-text;
  letter-spacing: 0.3px;

  &:after {
  	content: '';
  	display: inline-block;
  	width: 100%;
  }
}

.c-index__item, .c-index__icon {
	display: inline-block;
}

.c-index__icon {
  margin-top: -18px;
  margin-bottom: -3px;
}

@media all and (max-width: $bp-m-l) {
  .c-index {
    margin-bottom: 30px;
  }
}

@media all and (max-width: $bp-s) {
  .c-index {
    margin-bottom: 13px;
  }
}