.l-story-section {
	position: relative;
	padding: 84px (100% / 16) 47px;
}

.l-story-section__content {
	position: relative;
	z-index: 1;
	margin: 0 $col-padding-l * -1;

	> * {
		padding: 0 $col-padding-l;
	}
}

.l-story-section__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: auto;
	z-index: 0;
}

@media all and (max-width: $bp-m-l) {
	.l-story-section {
		padding-top: 52px;
	}
}

@media all and (max-width: $bp-s) {
  .l-story-section {
  	padding: 28px 22px 35px;
  }
}