.c-browserupgrade {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #efefef;
	z-index: 100;

	padding: 50px;

	p {
		max-width: 400px;
		font-size: 24px;
		color: black;
	}

	a:link {
		text-decoration: underline;
	}
}