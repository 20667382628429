.blink {
	animation-duration: 200ms;
	animation-name: blink;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

.blink-opacity {
	animation-duration: 200ms;
	animation-name: blink-opacity;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

.underline, .underline--a a {
	display: inline-block;
	position: relative;
	cursor: pointer;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 1px;
		background-color: currentColor;
		transition: width 0.4s ease-out;
	}

	&.is-hovering:after, &:hover:after {
		width: 100%;
	}
}

.underline--thick:after {
	height: 2px;
}

.underline--large:after {
	bottom: 2px;
}