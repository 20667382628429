
@keyframes blink {
  1% {
  	color: currentColor; }
  50% {
    color: transparent; }
  100% {
    color: currentColor; }
 }

@keyframes blink-opacity {
  1% {
	opacity: 1 }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; }
 }