.c-contained-img__wrapper {
	position: relative;
	display: block;
	min-width: 100%;
	height: 0;
	color: $white;

	.c-contained-img {
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.c-contained-img {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	color: $white;

	figcaption {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		background-color: rgba(0,0,0,0.4);
		opacity: 0;
	}

	p {
		font-family: $repl-stack;
		font-size: 28px;
		line-height: 36px;
		display: block;
		text-align: center;
	}

	&:hover {
		figcaption {
			opacity: 1;
		}
	}
}