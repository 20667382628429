.l-intro {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	z-index: 10;

	transition: transform 0.2s ease;

	&.is-disabled {
		z-index: 0;
		opacity: 0;
	}
}

.l-intro__container {
	position: fixed;
	left: 0;
	width: 100%;

	height: 100vh;
	margin-bottom: 100px;
	overflow-y: scroll;

	z-index: 1;

	&.is-disabled {
		z-index: 0;
		opacity: 0;
	}
}

.l-intro__container--first {
	background-color: #000;
}

.l-intro__close {
		position: absolute;
		top: 48px;
		right: 41px;
		z-index: 20;
	}

.l-intro__content, .l-intro__push {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
}

.l-intro__push {
	height: 110vh;
}

.l-intro__menu {
	position: fixed;
	top: 23px;
	right: 123px;
	width: 40px;
	height: 36px;

	z-index: 20;
	cursor: pointer;
}

.l-intro__container + .l-intro__container {
	z-index: 0;
}

.l-intro__scroll {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 37px;

	font-family: $repl-stack;
	text-align: center;
	font-size: 14px;
	letter-spacing: 0.3px;
}

@media all and (max-width: $bp-s) {
	.l-intro__close {
		right: 21px;
		top: 24px;

		.c-close-icon {
			width: 28px;
		}
		// display: none;
	}
}