.c-description {
	padding: 100px 100% / 20 * 5;

	font-family: $repl-stack;
}

.c-description__content {
	position: relative;
	z-index: 2;
	text-align: center;
}

.c-description__qa, .c-description__intro {
	text-align: left;
}

.c-description__title, .c-description__chapter, .c-description__qa p {
	font-size: $xs-text;
	letter-spacing: 0.15px;
}

.c-description__title, .c-description__qa h3 {
	font-family: $repl-bold-stack;
}

.c-description__title, .c-description__index {
	text-align: center;
}

.c-description__title {
	// margin-top: 98px;
}

.c-description__index {
	display: inline-block;
	margin: 18px auto 0;
}

.c-description__chapter {
	display: inline-block;
	margin: 0 8px;

	span {
		display: inline-block;
		margin-right: 1px;
	}
}

.c-description__intro {
	width: 100%;
	margin-top: 104px;

	font-family: $serif-stack;
	font-size: 25px;
	line-height: 35px;

	p {
		// margin-bottom: 12px;
	}
}

.c-description__qa {
	float: left;
	width: 50%;
	// margin: 43px 0 162px;
	margin: 43px 0 0;
	padding-right: 16px;

	h3 {
		margin-bottom: 14px;

		font-size: $xs-text;
		text-transform: uppercase;
	}

	p {
		line-height: 23px;
	}
}

.c-description__qa + .c-description__qa {
	padding-right: 0;
	padding-left: 16px;
}

.c-description__bg {
	position: absolute;
	left: 0;
	top: 0;

	z-index: 0;
}

@media all and (max-width: $bp-s) {
	.c-description {
		padding: 50px 24px;
	}

	.c-description__title {
		margin-top: 30px;
	}

	.c-description__index {
		margin-top: 16px;
	}

	.c-description__intro {
		width: 100%;
		padding: 0 20px;
		margin: 54px 0 35%;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
	}

	.c-description__qa {
		float: none;
		margin-bottom: 22px;
		width: 100%;
		padding-right: 0;

		text-align: center;

		h3 {
			font-size: 12px;
			letter-spacing: 0.75px;
		}

		p {
			font-size: 13px;
			letter-spacing: 0.2px;
			line-height: 20px;
		}
	}

	.c-description__qa + .c-description__qa {
		margin-bottom: 100px;
		padding-right: 0;
		padding-left: 0;
	}
}


@media all and (max-width: $bp-xs) {
	.c-description {
		padding: 30px 24px;
	}

	.c-description__title {
		margin-top: 40px;
	}

	.c-description__chapter {
		font-size: 13px;
		margin: 0 4px;
	}
}