.c-list {
	float: left;
	padding-right: 20px;
	margin-bottom: 30px;
	width: 50%;

	font-family: $serif-stack;

	ul {
		li {
			font-size: 30px;
			line-height: 40px;
			letter-spacing: 0.1px;
		}
	}
}

.c-list__title {
	margin-bottom: 31px;

	 h2 {
		font-size: 13px;
		font-family: $repl-bold-stack;
		letter-spacing: 0.75px;
	}
}

@media all and (max-width: $bp-s) {
	.c-list {
		margin-bottom: 0;
		padding-right: 0;
		width: 100%;
		float: none;
	}

	.c-list + .c-list {
		margin-top: 71px;
	}

	.c-list__title {
		margin-top: 8px;
		margin-bottom: 24px;

		 h2 {
			font-size: 12px;
			font-weight: normal;
			letter-spacing: 0.3px;
		}
	}

	.c-list__item {
		font-size: 20px;
		line-height: 25px;
	}
}