.c-story {
	margin-bottom: 149px;

	font-size: 47px;
	line-height: 85px;
	letter-spacing: -0.35px;
	font-family: $serif-stack;
	text-indent: 50px;

	p + p {
		margin-top: 26px;
	}
}

.c-story--small {
	margin-bottom: 0;
	width: 100% / 16 * 9;
	font-size: 15px;
	line-height: 23px;
	letter-spacing: 0;
	text-indent: 0;
	font-family: $repl-stack;

	p + p {
		margin-top: 0;
	}
}

@media all and (max-width: $bp-m-l) {
	.c-story {
		margin-bottom: 30px;
		font-size: 36px;
		line-height: 56px;
		text-indent: 42px;
	}

	.c-story--small {
		margin-top: 50px;
		margin-bottom: 0;
		font-size: 13px;
		line-height: 20px;
		text-indent: 0;
		width: 100%;
	}
}

@media all and (max-width: $bp-s) {
	.c-story {
		margin-top: 0;
		margin-bottom: 79px;
		font-size: 20px;
		line-height: 32px;
		text-indent: 20px;

		p + p {
			margin-top: 15px;
		}
	}

	.c-story--small {
		font-size: 14px;
		line-height: 23px;
	}
}