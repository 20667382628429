/// Create mixin for auto detecting breakpoint in Javascript
@mixin bp-small {
  @media only screen and (max-width: $bp-s) {
    @content;    
  }  
}

@mixin bp-medium {
  @media only screen and (min-width: $bp-s) {
    @content;    
  }  
}

@mixin bp-large {
  @media only screen and (min-width: $bp-m) {
    @content;    
  }  
}

@mixin define-breakpoint($name) {
    &:after {
      content: $name;
      display: none;
    }
}

// Set breakpoints to body
body {
  @include bp-small(){
      @include define-breakpoint("bp-small");
  }  

  @include bp-medium(){
      @include define-breakpoint("bp-medium");
  }

  @include bp-large(){
      @include define-breakpoint("bp-large");
  }
}