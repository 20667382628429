.c-full-img {
	height: auto;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.c-text-section + .c-full-img {
	margin-top: $case-margin-l;
}

@media all and (max-width: $bp-s) {
	.c-full-img {
		// overflow-x: hidden;

		img {
			// width: 160%;
			// margin-left: -30%;
		}
	}

	.c-text-section + .c-full-img {
		margin-top: $case-margin-s;
	}
}