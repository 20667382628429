.c-contact {
	font-family: $repl-stack;
}

.c-contact__header {
	display: none;
}

.c-contact__intro {
	font-size: 30px;
	line-height: 45px;
	letter-spacing: 0.1px;
	margin-right: 80px;
}

.c-contact__list {
	margin: 86px 0 73px;
	float: left;
}

.c-contact__list--s {
	font-size: 15px;
	line-height: 25px;
}

.c-contact__list--l {
	font-size: 45px;
	line-height: 55px;
	letter-spacing: 0.1px;

	li {
		a:after {
			bottom: 6px;
		}
	}
}

.c-contact__list--right {
	margin-top: 86px + 15px;
	float: right;
}

.c-contact__address--title {
	margin-bottom: 10px;
	text-transform: uppercase;

	h2 {
		font-family: $repl-bold-stack;
		font-size: 15px;
	}
}

.c-contact__address--phone {
	margin-top: 10px;
}

.c-contact__list--story {
	display: none;
}

@media all and (max-width: $bp-s) {
	.c-contact__header {
		display: block;
		margin: 9px 0 22px;
		font-size: 12px;
		letter-spacing: 0.3px;
	}

	.c-contact__intro {
		margin-right: 0;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 68px;
	}

	.c-contact__list {
		margin: 0;
		float: none;
	}

	.c-contact__list--l {
		display: none;
	}

	.c-contact__list--story {
		display: block;
		margin-top: -15px;

		li {
			font-size: 30px;
			line-height: 40px;
			letter-spacing: 0.1px;
		}
	}

	.c-contact__address {
		font-size: 15px;
		line-height: 20px;
	}

	.c-contact__address--title {
		h2 {
			font-family: $repl-bold-stack;
			font-size: 12px;
			text-transform: none;
		}
	}

	.c-contact__address--phone {
		margin: 14px 0 43px;
	}
}