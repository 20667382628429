.c-fullpage-col {
	position: relative;
	float: left;
	width: 50%;
	height: 100%;
	z-index: 2;
}

.c-fullpage-col--transition {
	transition: transform 600ms cubic-bezier(0.645, 0.045, 0.355, 1); // This has to be set manually in fullpage.js
}

.c-fullpage-col__slide {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
}

.c-fullpage-col__slide-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow:hidden;
}

.c-fullpage-col--fullwidth {
	// float: none;
	position: relative;
	width: 100%;
	z-index: 1;
}

.l-fullpage__inner--childmargins {
	.c-fullpage-col:not(.c-fullpage-col--childmargins) {
		margin: 0 3px;
		display: block;
		float: none;
		// padding: 0 3px;
		width: auto;
		z-index: 0;
	}
}

// Is set to prevent images from loading
.c-fullpage-col--right {
	margin-top: 9999px;
}

.fullpage-is-loaded {
	.c-fullpage-col--right {
		margin-top: 0;
	}
}

@media all and (orientation: portrait) {
	.c-fullpage-col	{
		width: 100%;
		height: 100%;
	}

	.c-fullpage-col--right {
		display: none;
	}

	.c-fullpage-col__slide {
		height: 100%;
	}

	.c-fullpage-col__slide--intro {
		height: 50%;
	}

	.c-fullpage-col__slide--large.c-fullpage-col__slide--right {
		float: right;
	}

	.c-fullpage-col__slide:not(.c-fullpage-col__slide--large) {
		clear: both;
	}

	.c-fullpage-col__slide--large {
		float: left;
		height: 100%;
		width: 50%;
	}
}