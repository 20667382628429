.c-footer {
	// float: left;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	font-family: $repl-stack;
}

.c-footer--vh {
	height: 100vh;
}

.c-footer--absolute {
	position: absolute;
}

.c-footer__content {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.c-footer__bg {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	position: absolute;
}

.c-footer__title {
	text-align: center;
	font-family: $serif-stack;
	font-size: 33px;
	letter-spacing: 0.1px;
}

.c-footer__subtitle {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
	font-size: 20px;
	letter-spacing: 0.35px;
	margin-top: -113px;
}

.c-footer__subtitle--alt {
	margin-top: 36px;

	font-family: $repl-bold-stack;
	font-size: 14px;
	letter-spacing: 0;
}

.c-footer__cta {
	display: inline-block;
	position: absolute;
	width: 100%;
	top: 50%;
	margin: 85px auto 0;

	left: 0;
	right: 0;

	text-align: center;
	font-size: 20px;
	letter-spacing: 0.2px;

	a {
		position: relative;
	}

	svg {
		margin: 0 -9px -3px 4px;
		opacity: 0;
		stroke: currentColor;
	}

	&:hover svg {
		opacity: 1;
	}
}

.c-footer__close {
	position: absolute;
	right: 35px;
	top: 36px;
}

.c-footer__cta--alt {
	margin-top: -130px;

	font-size: 28px;
	letter-spacing: 0.5px;

	a:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 2px;
		height: 1px;
		color: inherit;
		background-color: currentColor;
	}
}

.c-footer__arrow {
	position: absolute;
	right: 71px;
	top: 50%;
	margin-top: -16px;
}

.c-footer__footnote {
	display: inline-block;
	position: absolute;
	bottom: 51px;
	left: 70px;
	right: 70px;

	font-size: 12px;
	letter-spacing: 0.25px;
	text-align: justify;
	text-align-last: justify;
	color: inherit;

	li {
		display: inline-block;

		* {
			text-align: center;
		}
	}

	p {
		font-size: 12px;
	}

	&:hover {
		color: inherit;
	}

	&:after {
		content: '';
		display: inline-block;
		width: 100%
	}
}

@media all and (max-width: $bp-m) {
	.c-footer__footnote {
		left: 40px;
		right: 40px;
	}
}

@media all and (max-width: $bp-s) {
	.c-footer__title {
		font-size: 24px;
		letter-spacing: 0.5px;
	}

	.c-footer__subtitle {
		margin-top: -87px;
		font-size: 12px;
	}

	.c-footer__cta {
		margin-top: 69px;
		font-size: 12px;
		letter-spacing: 0.6px;
	}

	.c-footer__footnote {
		left: 6.25%;
		right: 6.25%;
		text-align: center;

		li {
			margin: 0 10px;
		}

		li:not(.c-footer__footnote--bp-s) {
			display: none;
		}
	}
}

@media all and (max-width: 370px) {
	.c-footer__content {
		padding: 0 20px;
	}

	.c-footer__subtitle {
		left: 20px;
		right: 20px;
		width: auto;
	}

	.c-footer__footnote {
		bottom: 26px;
	}

	.c-footer__footnote {
		left: 0;
		right: 0;
		text-align: center;

		li {
			margin: 0;
		}
	}
}