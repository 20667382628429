.l-header {
	position: relative;
	margin: 0 $window-padding-l;
	padding: 0 $col-padding-l;
	height: $header-height-l;

	transition: transform 0.3s ease-out;
}

.l-header--is-hidden {
	transform: translateY(-100%);
}

.menu-is-sticky {
	.l-header {
		position: fixed;
		margin: 0;
		top: 0;
		left: 0;
		right: 0;
		z-index: 3;
	}
}

@media all and (max-width: $bp-s) {
	.l-header {
		margin: 0 $window-padding-s;
		padding: 0;
		height: $header-height-s;
	}

	.menu-is-sticky {
		.l-header {
			position: fixed;
			margin: 0;
		}
	}
}