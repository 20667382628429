.c-toggle-menu {
	display: block;
	position: absolute;
	right: $col-padding-l;
	bottom: 29px;
	width: 40px;
	height: 36px;
	padding: 10px;
	margin: -10px;
}

.menu-is-sticky {
	.c-toggle-menu {
		right: $window-padding-l;
		margin-right: -10px + $col-padding-l;
	}
}

.c-toggle-menu__icon {
	position: relative;
	width: 100%;
	height: 100%;

	&:before, &:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		width: 19px;

		transform-origin: 50% 50%;
		transition: all 0.2s ease;
	}

	&:before {
		top: 0;
		height: 9px;
		border-top: 2px currentColor solid;
		border-bottom: 2px currentColor solid;
	}

	&:after {
		bottom: 0;
		height: 2px;
		background-color: currentColor;
	}
}

.menu-is-open {
	.c-toggle-menu__icon {
		&:before {
			margin-left: -2px;
			margin-top: 6px;
			border-bottom: none;
			transform: rotate(45deg);
		}

		&:after {
			margin-bottom: 7px;
			transform: rotate(-45deg);
		}
	}
}

@media all and (max-width: $bp-s) {
	.c-toggle-menu {
		top: 26px;
		right: 0;
	}

	.menu-is-sticky {
		.c-toggle-menu {
			right: $window-padding-s;
			margin-right: 0;
		}
	}
}