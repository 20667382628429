.l-pagination {
	position: absolute;
	right: 30px;
	bottom: $pagination-height-l * -1;
	height: $pagination-height-l;

	font-family: $repl-stack;

	li {
		display: inline;
	}

	button {
		padding: 17px 7px;
		background-color: transparent;
		border: none;
		outline: none;

		&:active {
			border: none;
			outline: none;
		}
	}
}