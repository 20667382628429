.l-case > * {
	margin-bottom: $case-margin-l;

	&:last-child {
		margin-bottom: 0;
	}

	&:last-child .c-text-section {
		margin-bottom: 0;
	}
}

.l-case {
	.scroll-content {
		// position: relative;
		// z-index: 1;
		// background-color: #efefef;
		// margin-bottom: 100vh;
	}
}

.l-case {
	padding-bottom: 50px;
}

.l-case__footer {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;
	z-index: 0;

	pointer-events:all;
}

.l-case__footer-push {
	position: relative;
	height: 100vh;
	left: 0;
	width: 1px;
}

@media all and (max-width: $bp-s) {
	.l-case > * {
		margin-bottom: $case-margin-s;

		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}
}