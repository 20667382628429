.v-layout {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 500;
}

.v-layout__col {
	float: left;
	position: relative;
	width: 100% / 20 * 1;
	height: 100%;

	&:after {
		content: '';

		position: absolute;
		top: 0;
		left: $col-padding-l;
		right: $col-padding-l;
		bottom: 0;
		background-color: rgba(0,0,0,0.1);
	}
}

.v-layout, .v-layout__col {
	pointer-events: none;
}