.l-menu {
	position: fixed;
	right: $window-padding-l;
	top: $header-height-l;
	bottom: $window-bottom-padding-l;
	width: $menu-width-l;
	margin-right: $col-padding-l;

	&:after {
		content: '';
		position: absolute;
		left: 100%;
		width: 100%;
		top: 0;
		bottom: 0;
		background-color: currentColor;
	}
}

.l-menu__inner {
	margin: 122px 26px 0;
}

.menu-is-open {
	.l-menu {
		z-index: 10;
	}
}

@media all and (max-width: $bp-s) {
	.l-menu {
		right: $window-padding-s;
		top: $header-height-s;
		bottom: $window-bottom-padding-s;
		margin-right: 0;
		width: $menu-width-s;
	}

	.l-menu__inner {
		margin: 41px 18px 0;
	}
}

.l-menu--extend {
	bottom: 0;
}