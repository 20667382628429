.c-top {
	position: fixed;
	right: 24px;
	bottom: 24px;
	z-index: 9;
	cursor: pointer;

	circle {
		fill: currentColor;
	}
}

@media all and (max-width: $bp-s) {
	.c-top {
		right: 36px;
	}
}