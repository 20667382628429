.l-slideshow {
	position: relative;
	margin-bottom: $pagination-height-l;
	padding-bottom: $slideshow-height-l;
	width: 100%;
	min-height: auto;
}

.l-slideshow__inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.slick-list {
	height: 100%;
	// overflow-y: hidden;
}

@media all and (max-width: $bp-s) {
	div.l-slideshow {
		margin-bottom: 50px;
	}
}