.l-wrapper {
	position: relative;
	margin: 0 $window-padding-l;
	padding: 0 $col-padding-l;
	z-index: 2;

	transition: transform 0.8s ease;
}

.l-wrapper--no-padding {
	padding: 0;
}

.menu-is-open {
	.l-wrapper {
		transform: translate3d($menu-width-l * -1, 0, 0);
	}
}

@media all and (max-width: $bp-s) {
	.l-wrapper {
		margin: 0 $window-padding-s;
		padding: 0;
	}

	.menu-is-open {
		.l-wrapper {
			transform: translate3d($menu-width-s * -1, 0, 0);
		}
	}
}