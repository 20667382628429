.c-text-section {
	margin: ($case-margin-l * -1) 0 0;
	padding: 88px 66px 79px;

	font-family: $repl-stack;
}

.l-slideshow + .c-text-section {
	margin-top: ($case-margin-l * -1) + 52px;
}

.c-text-section + .h-full-vh {
	margin-top: $case-margin-l;
}

.c-text-section__large, .c-text-section__small {
	max-width: 745px;
}

.c-text-section__large {
	margin-bottom: 12px;

	font-family: $serif-stack;
	font-size: 30px;
	line-height: 35px;
	letter-spacing: 0.1px;
}


.c-text-section__large + .c-text-section__type {
	padding-top: 67px;
}

.c-text-section__small, .c-text-section__type {
	font-size: $xs-text;
}

.c-text-section__small {
	margin-bottom: 52px;

	letter-spacing: 0.6px;
	line-height: 23px;
}

.c-text-section__type {
	display: inline-block;
	letter-spacing: 0.3px;
}

@media all and (max-width: $bp-s) {
	.c-text-section {
		margin: ($case-margin-s * -1) 0 0;
		padding: 58px 21px 36px;
	}

	.l-slideshow + .c-text-section {
		margin-top: ($case-margin-s * -1) + 65px;
	}

	.c-text-section + .h-full-vh {
		margin-top: $case-margin-s;
	}

	.c-text-section__large {
		// margin-bottom: 51px;
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 32px;
	}

	.c-text-section__large + .c-text-section__type {
		padding-top: 37px;
	}

	.c-text-section__small {
		// margin-bottom: 40px;
		// display: none;
	}
}